import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-round-button',
  template: `
    <button
      class="round-button"
      [class.primary]="type === 'primary'"
      [class.secondary]="type === 'secondary'"
      nz-button
      nzType="default"
      [nzShape]="'circle'"
      (click)="btnClick.emit($event)"
    >
      <ng-content></ng-content>
    </button>
  `,
  styleUrls: ['round-button.component.less']
})
export class RoundButtonComponent {
  @Input() public type: 'primary' | 'secondary' = 'primary';
  @Output() public btnClick = new EventEmitter<MouseEvent>();
}

@NgModule({
  declarations: [RoundButtonComponent],
  exports: [RoundButtonComponent],
  imports: [NzButtonModule, NzIconModule]
})
export class RoundButtonModule {}
